.skeleton_container {
  display: flex;
  width: 100%;
}

.skeleton {
  height: 250px;
  width: 100%;
}

.skeleton_graph {
  width: 100%;
  height: 330px;
}
