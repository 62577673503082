.verification-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
}

.email_icon {
  width: 100px;
  height: auto;
  margin-bottom: 20px;
}

h1 {
  font-size: 1.5em;
  margin-bottom: 15px;
  text-align: center;
  color: #28282b;
}

.email_verify_button {
  padding: 10px;
  display: block;
  border: none;
  color: #fff;
  font-size: 1em;
  border-radius: 5px;
  width: 290px;
  margin: 10px auto;
}

p {
  font-size: 18px;
  margin-bottom: 10px;
  text-align: center;
  color: #28282b;
}
.company_logo {
  position: absolute;
  width: 150px;
}

@media only screen and (max-width: 600px) {
  .verification-container {
    padding: 20px;
  }
  img {
    width: 80px;
  }

  h1 {
    font-size: 1em;
  }

  p {
    font-size: 0.9em;
  }

  button {
    font-size: 0.9em;
  }
}
