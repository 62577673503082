.client_container,
.admin_container {
  display: flex;
}

.client_content_container,
.admin_content_container {
  margin-left: 256px;
  flex: 1;
}

.client_appbar,
.admin_appbar {
  /* background-color: blue; */
  height: 80px;
  display: flex;
  /* border: 2px solid blue; */
  align-items: center;
  padding: 20px;
  background-color: white;
}
/*  */
.client_content,
.admin_content {
  padding: 24px;
  /* border: 2px solid red; */
}

.menu_icon_btn {
  display: none;
  color: #14b8a6;
  font-size: 30px;
  cursor: pointer;
  margin-right: 10px;
}

.admin_appbar_title {
  font-size: 20px;
}

.admin_appbar_title span {
  font-weight: 900;
  color: var(--primary-color-bright-shade);
}

#location_icon {
  display: block;
  color: red;
  font-size: 30px;
}
@media (max-width: 1024px) {
  .overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Adjust the alpha value as needed */
    z-index: 9998; /* Set a lower z-index than the sidebar */
  }

  .client_container,
  .admin_container {
    display: block;
  }
  .client_sidebar,
  .admin_sidebar {
    width: 0;
    overflow: hidden;
  }

  .client_sidebar.open,
  .admin_sidebar.open {
    width: 256px;
    /* width: 100%; */
    z-index: 9999;
  }

  .client_content_container,
  .admin_content_container {
    margin-left: 0; /* Reset margin when sidebar is hidden */
  }
  .client_appbar,
  .admin_appbar {
    /* background-color: #14b8a6; */
    height: 60px;
    color: white;
    /* box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1),
      0px 10px 15px -3px rgba(0, 0, 0, 0.1),
      0px 10px 15px -3px rgba(0, 0, 0, 0.1); */
    padding: 20px;
    /* border: 1px solid red; */
  }
  .client_content,
  .admin_content {
    padding: 20px 10px;
  }
  .menu_icon {
    display: block;
  }
  .menu_icon_btn {
    display: block;
    flex: 1;
  }
  #location_icon {
    display: none;
  }

  .overlay.open {
    display: block;
  }
  .admin_appbar_title {
    font-size: 16px;
    font-weight: 500;
  }
}
