.iaq_cards {
  text-align: center;
  background-color: #fff;
  padding: 10px;
  border-radius: 5px;
  /* border: 2px solid red; */
  width: 100%;
  display: grid;
  place-items: center;
  /* height: 150px; */
}

.iaq_cards .iaq_card_title {
  font-size: 50px;
  font-weight: bold;
  color: #344a53;
  display: block;
  margin-bottom: 10px;
}

.iaq_cards .iaq_card_description {
  font-size: 16px;
  font-weight: 500;
  color: #344a53;
  display: block;
  margin-bottom: 10px;
}

.iaq_card_time {
  font-size: 12px;
  font-weight: 500;
  color: gray;
}
