#add_schedule_alert_box {
  text-align: left;
  color: red;
}

#add_schedule_error {
  text-align: left;
  font-size: 14px;
  margin-top: -9px;
  margin-bottom: 0;
  padding-top: 0;
  color: red;
}

#add_schedule_btn {
}
#add_schedule_btn_disabled {
  background-color: #edfcf4;
  color: black;
  cursor: not-allowed;
}

#add_schedule_textarea {
  width: 100%;
  height: 80px;
  padding: 10px;
}
