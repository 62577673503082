.btu_graph_container {
  width: 100%;
  background-color: white;
  padding: 2rem;
}

.btu_cards {
  text-align: center;
  background-color: white;
  padding: 10px;
  border-radius: 5px;
  /* border: 2px solid red; */
  width: 100%;
  display: grid;
  place-items: center;
  height: 150px;
}

.btu_cards div:nth-child(1) {
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 0px;
  color: #18a558;
}

.btu_cards div:nth-child(2) {
  font-size: 18px;
  font-weight: 500;
  margin: 0;
  color: gray;
}
