.forgot-password-outer-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #f0f0f0;
}

.forgot-password-inner-container {
  width: 100%;
  max-width: 550px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
}

.forgot-password-inner-container img {
  width: 100px;
  height: 100px;
  display: block;
  margin: 0 auto;
}

.forgot-password-form {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}

.forgot-password-form h2 {
  font-size: 1.5em;
  margin-bottom: 10px;
  text-align: center;
}

.forgot-password-form p {
  margin-bottom: 20px;
  text-align: center;
}

.forgot-password-form label {
  font-weight: bold;
  margin-bottom: 10px;
  text-align: left;
}

.forgot-password-form input {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.forgot-password-form button {
  background-color: #5ae4a7;
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.forgot-password-form .message {
  margin-top: 20px;
  color: #4caf50;
}

.company_logo {
  position: absolute;
  width: 150px;
}

.message {
  text-align: left;
  font-size: 14px;
}
