.activeMenu {
  color: red;
}

.client_sidebar,
.admin_sidebar {
  width: 256px;
  position: fixed;
  height: 100vh;
  /* background-color: #14b8a6; */
  /* background-color: #006d86; */
  /* background-color: #0d796b; */
  background-color: #008675;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 0;
  overflow-y: auto;
  transition: width 0.3s ease; /* Add smooth transition for better user experience */
}

.client_menu_items,
.admin_menu_items {
  display: flex;
  flex-direction: column;
}

.client_menu_item,
.admin_menu_item {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 95%;
  margin: 0 auto;
  padding: 10px 12px;
  font-family: "DMSans";
  border-radius: 5px;
  color: white;
  margin-bottom: 10px;
}

.client_menu_item:hover,
.admin_menu_item:hover {
  /* background-color: var(--primary-color); */
  /* color: var(--primary-color-bright-shade); */
  background-color: white;
  color: #008675;
}

.client_menu_item_active,
.admin_menu_item_active {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 95%;
  margin: 0 auto;
  padding: 10px 12px;
  font-family: "DMSans";
  border-radius: 5px;
  margin-bottom: 10px;
  color: var(--primary-color-bright-shade);
  background-color: white;
}

.client_menu_text,
.admin_menu_text {
  padding-left: 10px;
}
