.session_timeout_container {
  height: 100vh;
  width: 100%;
  display: grid;
  place-items: center;
  background-color: white;
}

.session_timeout_container img {
  display: block;
  width: 300px;
  margin: 0 auto;
}

.session_timeout_container div {
  width: 500px;
}

.session_timeout_container button {
  display: block;
  margin: 0 auto;
  width: 300px;
}

.session_timeout_container .logo {
  position: absolute;
  width: 100px;
  left: 20px;
  top: 20px;
}

@media only screen and (max-width: 768px) {
  .session_timeout_container img {
    width: 200px; /* Corrected syntax */
  }

  .session_timeout_container div {
    width: 80%; /* Adjusted width */
    text-align: center; /* Center text */
  }

  .session_timeout_container button {
    width: 200px; /* Adjusted width */
  }
}
