.center-header {
  text-align: center !important;
}

/* Common Styling for Tables */
table {
  border-collapse: collapse;
  font-family: "DMSans";
  width: 100%;
}

table,
th,
td {
  border: 1px solid #ccc;
}

th,
td {
  padding: 8px;
  text-align: center;
  font-family: "DMSans";
}

thead tr {
  text-align: left;
  background-color: var(--primary-color-bright-shade);
  color: var(--text-white-color);
}

tbody tr {
  border-bottom: 1px solid #dddddd;
}

tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

tbody tr:last-of-type {
  border-bottom: 2px solid #009879;
}
td {
  white-space: nowrap;
  min-width: auto;
  max-width: "160px";
  text-align: center;
}

.date_picker_container {
  display: flex;
  justify-content: flex-end;
}

.date_picker {
  margin-left: 20px;
}

.date_picker input {
  width: 250px;
}

.search_btn_container {
  margin-left: 20px;
  margin-top: 22px;
}

.search_btn {
  padding: 10px;
  font-size: 19px;
  width: 100px;
  letter-spacing: 1px;
  font-weight: 500;
}

#device_details_container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  overflow-x: auto;
  gap: 20px;
}

#device_details_container div {
  margin-bottom: 10px;
}

#device_details_container div p {
  margin: 0;
  font-size: 14px;
}

#edit_btns_container {
  margin: 20px 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

#edit_btns_container button {
  margin-right: 20px;
  display: block;
}

@media (max-width: 480px) {
  .date_picker_container {
    flex-direction: column;
    justify-content: flex-start;
  }
  .date_picker {
    margin-left: 0;
  }
  .search_btn_container {
    margin-left: 0px;
    margin-top: 10px;
  }
  .date_picker input {
    width: 100%;
  }
  .search_btn {
    font-size: 14px;
    width: 100px;
  }
}

@media (max-width: 768px) {
  .date_picker_container {
    flex-direction: column;
    justify-content: flex-start;
  }
  .date_picker {
    margin-left: 0;
  }
  .search_btn_container {
    margin-left: 0px;
    margin-top: 10px;
  }
  .date_picker input {
    width: 100%;
  }
}

@media (max-width: 1024px) {
  .date_picker_container {
    flex-direction: column;
    justify-content: flex-start;
  }
  .date_picker {
    margin-left: 0;
  }
  .search_btn_container {
    margin-left: 0px;
    margin-top: 10px;
  }
  .date_picker input {
    width: 100%;
  }
}
