.ahu_table_head {
  background-color: var(--primary-color-bright-shade);
  padding: 10px;
  color: white;
  font-weight: 700;
  /* background-color: white; */
  /* color: var(--primary-color-bright-shade); */
  /* color: #344a53; */
}

.ahu_table_cell {
  font-weight: 500;
  color: #344a53;
  font-size: 14px;
}

.ahu_table,
.ahu_table th,
.ahu_table td {
  border: none;
  padding: 12px 0;
}

/* .ahu_table tr:nth-of-type(even) td {
  background-color: var(--primary-color-bright-shade);
  color: white;
} */

@media screen and (max-width:600px) {
  .ahu_table_cell {
    font-size: 10px;
  }
}
