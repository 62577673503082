.search-bar {
  display: flex;
  align-items: center;
}

.search-dropdown {
  /* padding: 0 10px; */
}

.input_container {
  width: 60%;
  margin: 0 auto;
}

.search_box_container {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
}

.search-input {
  padding: 10px;
  width: 500px;
  margin-bottom: 0;
}

.search_icon {
  background-color: #069a8e;
  color: white;
  padding: 10px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

/* Style the select element */
select {
  /* background-color: #272829; */

  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

/* Style the search input */
input {
  width: 100%;
  background-color: #f0f0f0;
  border-radius: 0;
  background-color: white;
}

.filter_container {
  position: relative;
  margin-left: 20px;
}

.filter_container button {
  font-family: "DMSans";
  background-clip: padding-box;
  background-color: var(--primary-color-bright-shade);
  border: 1px solid transparent;
  border-radius: 0.25rem;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.25;
  margin: 0;
  padding: 8px 16px;
  position: relative;
  text-decoration: none;
  transition: all 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  width: auto;
  height: 100%;
}

.reset_btn {
  margin-left: 20px;
}

@media (max-width: 480px) {
  .input_container {
    width: 100%;
  }
  .search_icon {
    width: 50px;
  }
  .search-input {
    width: calc(100% - 50px);
  }
  .reset_btn {
    margin-left: 20px;
    margin-top: 5px;
    font-size: 14px;
    padding: 8px 16px;
  }
  .filter_container {
    margin-left: 0;
    margin-top: 5px;
  }
}
@media (max-width: 768px) {
  .input_container {
    width: 100%;
  }
  .search_icon {
    width: 50px;
  }
  .search-input {
    width: calc(100% - 50px);
  }
  .reset_btn {
    margin-left: 20px;
    margin-top: 5px;
    font-size: 14px;
    padding: 8px 16px;
  }
  .filter_container {
    margin-left: 0;
    margin-top: 5px;
  }
}

@media (max-width: 1024px) {
  .input_container {
    width: 100%;
  }
  .search_icon {
    width: 50px;
  }
  .search-input {
    width: calc(100% - 50px);
  }
  .reset_btn {
    margin-left: 20px;
    margin-top: 20px;
    font-size: 14px;
    padding: 8px 16px;
  }
  .filter_container {
    margin-left: 0px;
    margin-top: 20px;
  }
}

@media (max-width: 1200px) {
  .input_container {
    width: 100%;
  }
}

@media (min-width: 1200px) {
  .input_container {
    width: 100%;
  }
}
