@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

/**
  
 DM SANS - paragraph, tables
 DM SERIF DISPLAY - headings, h1,h2,h3,h4,h5,h6

*/

:root {
  --primary-color: #0b666a;
  --secondary-color: #ff8945;
  /* --primary-color-bright-shade: #04aa6d; */
  /* --primary-color-bright-shade: #14b8a6; */
  /* --primary-color-bright-shade: #006d86; */
  /* --primary-color-bright-shade: #0d796b; */
  --primary-color-bright-shade: #008675;

  --primary-color-light-shade: #35a29f;
  --primary-color-full-shade: #97feed;
  --text-dark-color: #272829;
  --text-dark-heading-color: #272727;
  --gray-shade-color: #344a53;
  --text-light-color: #eeeded;
  --text-white-color: #fff;
  --background-color: #f0f0f0;
  --white-background-color: #fff;
  --box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  --delete-button-color: #be2217cb;
}

::selection {
  background-color: var(--primary-color-bright-shade);
  color: var(--text-white-color);
}

html {
  font-size: 16px;
}

body {
  background-color: var(--background-color);
}

/* Styling for headings */
h1 {
  font-size: 28px;
  margin-top: 20px;
  margin-bottom: 10px;
  font-family: "DMSans";
}

h2 {
  font-size: 24px;
  margin-top: 18px;
  margin-bottom: 8px;
  font-family: "DMSans";
}

h3 {
  font-size: 20px;
  margin-top: 16px;
  margin-bottom: 6px;
  font-family: "DMSans";
}

h4 {
  font-size: 18px;
  margin-top: 14px;
  margin-bottom: 4px;
  font-family: "DMSans";
}

h5 {
  font-size: 16px;
  margin-top: 12px;
  margin-bottom: 2px;
  font-family: "DMSans";
}

h6 {
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 2px;
  font-family: "DMSans";
}

p {
  font-family: "DMSans";
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  font-size: 1.125rem;
  line-height: 2rem;
  font-family: "DMSans";
}

button {
  font-family: "DMSans";
  background-clip: padding-box;
  background-color: var(--primary-color-bright-shade);
  border: 1px solid transparent;
  border-radius: 0.25rem;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.25;
  margin: 0;
  padding: 8px 16px;
  position: relative;
  text-decoration: none;
  transition: all 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  width: auto;
}

.delete_btn {
  background-color: var(--delete-button-color);
  color: var(--text-white-color);
}

.update_btn,
.add_btn {
  background-color: var(--primary-color-bright-shade);
  color: var(--text-white-color);
}

button:hover,
button:focus {
  /* background-color: #05d085; */
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
}

button:hover {
  transform: translateY(-1px);
}

button:active {
  /* background-color: #079360; */
  box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
  transform: translateY(0);
}

.delete_btn:hover,
.delete_btn:focus {
  background-color: #9b1c12cb;
}

.delete_btn:active {
  background-color: #9b1c12cb;
}

.card {
}

/* Common Styling for Inputs and Labels */
label {
  display: block;
  margin-bottom: 0.5em;
  color: var(--text-dark-color);
  font-weight: 600;
  font-family: "DMSans";
  font-size: 14px;
}

/* Common Styling for Inputs */
input,
select {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  outline: none;
  transition: border-color 0.3s ease;
  font-family: "DMSans";
  margin-bottom: 10px;
  display: block;
  width: 100%;
}

/* Hover and Focus Styles for Inputs */
input:hover,
input:focus {
  border-color: var(--primary-color-bright-shade);
}

option {
  /* background-color: var(--primary-color-bright-shade); */
  /* color: var(--text-white-color); */
}

select {
  /* background-color: var(--primary-color-bright-shade); */
  /* color: var(--text-white-color); */
}

option:focus,
option:hover {
  background-color: #05d085;
  color: var(--text-white-color);
}

/* Disabled Style for Inputs */
input:disabled {
  background-color: var(
    --background-color
  ); /* Change to your preferred disabled color */
  cursor: not-allowed;
}

/* .table_container {
  overflow-x: auto;
} */

/* Common Styling for Tables */
/* table {
  border-collapse: collapse;
  font-family: "DMSans";
  width: 100%;
}

table,
th,
td {
  border: 1px solid #ccc;
}

th,
td {
  padding: 8px;
  text-align: left;
  font-family: "DMSans";
}

thead tr {
  text-align: left;
  background-color: var(--primary-color-bright-shade);
  color: var(--text-white-color);
}

tbody tr {
  border-bottom: 1px solid #dddddd;
}

tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

tbody tr:last-of-type {
  border-bottom: 2px solid #009879;
}
td {
  white-space: nowrap;
  min-width: 160px;
} */

.container {
  padding: 20px;
  /* background: white; */
  background-color: var(--text-white-color);
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

li {
  margin-bottom: 10px;
  font-family: "DMSans";
  font-weight: 600;
  color: var(--text-dark-heading-color);
}

a {
  text-decoration: none;
  font-family: "DMSans";
  color: var(--text-dark-color);
}

span {
  font-family: "DMSans";
}

img {
  display: block;
}

.title {
  font-family: "DMSerifDisplay";
  font-weight: 600;
  font-size: 1.2rem;
  color: var(--secondary-color);
}

.layout_wrapper {
  display: flex;
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 260px;
  background-color: #069a8e;
  padding: 40px;
  overflow-y: auto;
  transition: transform 0.3s ease; /* Add transition for the transform property */
  z-index: 999;
}

.content {
  margin-left: 260px; /* Width of the sidebar */
  padding: 20px;
  flex: 1;
}
.content .appbar {
  display: flex;
  justify-content: space-between;
  font-size: 1.2rem;
  margin-bottom: 20px;
}

.appbar div:first-child {
  display: flex;
  align-items: center;
}

.appbar p:nth-child(2) {
  /* color: var(--secondary-color); */
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
}

/* Styles for the popover content */
.popover-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

/* Styles for the "Yes" and "No" buttons */
.popover-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.popover-buttons button {
  padding: 8px 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

/* Style for the "Yes" button */
.popover-buttons button.yes-btn {
  background-color: #e74c3c;
  color: #fff;
}

/* Style for the "No" button */
.popover-buttons button.no-btn {
  background-color: #34db85;
  color: #fff;
}

/* Example CSS */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
  z-index: 10; /* Place the overlay above the content */
  display: none; /* Initially hidden */
}

.overlay.active {
  display: block; /* Show the overlay when active */
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ); /* Semi-transparent black overlay when active */
}

/* Adjust content width on smaller screens */
@media (max-width: 768px) {
  .content {
    margin-left: 0; /* Remove left margin for smaller screens */
    width: 100%;
    padding: 10px;
  }

  html {
    font-size: 16px;
  }

  .content .appbar p {
    font-size: 1.3rem;
  }

  /* Responsive font sizes */
  .sidebar {
    font-size: 16px;
    /* display: none; */
  }

  .sidebar h2 {
    font-size: 24px;
  }

  .sidebar li {
    font-size: 18px;
  }

  .content {
    font-size: 20px;
  }
}

/* Additional font size for even smaller screens (e.g., mobile portrait) */
@media (max-width: 480px) {
  html {
    font-size: 14px;
  }

  h3 {
    font-size: 16px;
  }

  th,
  td {
    font-size: 14px;
  }

  button {
    font-size: 12px;
    padding: 4px 8px;
  }

  .content .appbar p {
    font-size: 1rem;
  }

  .sidebar {
    font-size: 14px;
  }
  .sidebar {
    left: -260px;
  }

  .sidebar h2 {
    font-size: 20px;
  }

  .sidebar li {
    font-size: 16px;
  }
  .sidebar.open {
    left: 0; /* Visible when open */
    transform: translateX(0); /* Move the sidebar into view when open */
  }

  .sidebar.closed {
    transform: translateX(
      -260px
    ); /* Move the sidebar out of view when closed */
  }

  .content {
    font-size: 18px;
    padding: 10px;
  }
  .container {
    padding: 10px;
  }
  .appbar {
    margin-bottom: 5px;
  }
  .layout_wrapper {
    flex-direction: column;
  }
  .content .appbar {
    margin-bottom: 10px;
  }
  .mobile_menu_bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--primary-color-bright-shade);
    color: white;
    padding: 10px;
    font-family: "DMSerifDisplay";
  }
  .mobile_menu_bar div:nth-child(2) {
    display: flex;
  }
}
