.access-denied-container {
  text-align: center;
  margin: 0 auto;
  max-width: 100%;
  height: auto;
  background-color: #f8f8f8;
  padding: 30px;
  border-radius: 8px;
}

.access-denied-title {
  font-size: 5rem;
  color: #b91919;
  margin-bottom: 20px;
  font-weight: 700;
}

.access-denied-message {
  font-size: 1rem;
}

.access-denied-image {
  display: block;
  margin: 0 auto;
  max-width: 100%; /* Ensure image doesn't overflow container */
  height: auto; /* Maintain aspect ratio */
}

/* Responsive Styles */
@media only screen and (max-width: 768px) {
  .access-denied-container {
    padding: 20px;
  }

  .access-denied-title {
    font-size: 4rem;
  }

  .access-denied-message {
    font-size: 1rem;
  }
  .access-denied-image {
    max-width: 250px !important;
  }
}

@media only screen and (max-width: 480px) {
  .access-denied-title {
    font-size: 3rem;
  }
  .access-denied-image {
    max-width: 150px !important;
  }
}
