.login_container {
  width: 100%;
  height: 100vh;
}
.left_side {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  color: white;
  background-image: linear-gradient(
    to bottom,
    #005555,
    #006664,
    #007772,
    #008880,
    #069a8e
  );
  position: relative;
  padding: 5rem;
  font-size: 20px;
  font-weight: 500;
  font-family: "DMSans";
  line-height: 1.5;
}

.login_btn:hover {
  background-color: #005555;
  color: white;
}

.right_side {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 5rem;
  font-size: 20px;
  font-weight: 500;
  font-family: "DMSans";
  line-height: 1.5;
}

.right_side .submit_btn {
  width: "100%";
  font-size: 18px;
  font-weight: 600;
  background: linear-gradient(
    to bottom,
    #005555,
    #006664,
    #007772,
    #008880,
    #069a8e
  );
  color: "white";
  letter-spacing: 2;
  margin-bottom: 5;
}

.submit_btn:hover {
  background-color: "#08cbbb";
  color: "white";
}

@media (max-width: 480px) {
  .right_side {
    padding: 1rem;
  }
}

@media (max-width: 768px) {
  .right_side {
    padding: 1rem;
  }
}

@media (max-width: 1024px) {
  .right_side {
    padding: 1rem;
  }
}

@media (max-width: 1200px) {
  .right_side {
    padding: 1rem;
  }
}
