.customer_container {
  padding: 20px;
  position: relative;
}

.customer_container .savings {
  display: flex;
  justify-content: end;
}

.customer_container .savings div:nth-child(1) {
  font-size: 1.1rem;
  font-family: "Montserrat";
  font-weight: 500;
  text-align: center;
  color: #11765b;
  margin-bottom: 0.3rem;
}

.customer_container .savings div:nth-child(2) {
  font-size: 1.2rem;
  color: gold;
  font-family: "Montserrat";
  font-weight: 600;
  text-align: center;
}

.customer_container h4 {
  margin-bottom: 1.1rem;
}
.customer_container h6 {
  margin-bottom: 0.9rem;
}
.customer_info {
  display: flex;
  flex-wrap: wrap;
}

.customer_container .customer_data:not(:last-child) {
  width: calc(100% / 5);
  margin-bottom: 2rem;
}

table {
  width: 100%;
  margin-bottom: 1.1rem;
}
tr {
}
.device_table_head td {
  background-color: #11765b;
  color: white;
  padding: 0.5rem;
  font-family: "DMSans";
  font-size: 0.9rem;
}

.device_table_body td {
  padding: 0.5rem;
  font-family: "DMSans";
  font-size: 0.8rem;
}

