@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");

.not-found-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: white;
  color: #14b8a6; /* Primary color */
}

.clairco_logo {
  position: absolute;
  width: 100px;
  top: 20px;
  left: 20px;
}

.not-found-container .image {
  width: 100%; /* Set the image width to 100% */
  max-width: 750px; /* Limit the maximum width of the image */
}

.not-found-container h1 {
  font-size: 2rem;
  margin-bottom: 1rem;
  font-family: "Raleway", sans-serif;
  color: #14b8a6;
}

.not-found-container h2 {
}

.not-found-container p {
  font-family: "Raleway", sans-serif;

  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 2rem;
}

.error-code {
  font-size: 5rem;
  margin-bottom: 0.5rem;
}

/* Add media queries for responsiveness */

@media (max-width: 768px) {
  .not-found-container h1 {
    font-size: 1.5rem;
  }
  .not-found-container p {
    font-size: 1rem;
  }

  .error-code {
    font-size: 2rem;
  }
}
