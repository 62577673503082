.chiller_table {
}

.chiller_table button {
  /* background-color: transparent;
  color: #237f73;
  cursor: pointer;
  border: 2px solid #344a53;
  border-radius: 25px; */
  padding: "8px";
}

.chiller_table button:hover {
  /* transform: none;
  box-shadow: none;
  background-color: transparent; */
}
