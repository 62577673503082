/* .update_ahu_btn {
  padding: 0;
  font-weight: 600;
  color: var(--primary-color-bright-shade);
} */
.ahu_details {
  padding: 20px;
  background-color: white;
}

.ahu_details h4 {
  margin-top: 30px;
  margin-bottom: 10px;
  color: #343434;
}

.ahu_details label {
  color: #343434;
}

.update_ahu_btn:hover {
  box-shadow: none;
}

#edit_btns_container {
  margin: 20px 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

#edit_btns_container button {
  margin-right: 20px;
  display: block;
}

.back_btn {
  display: flex;
  align-items: center;
}
.back_btn span {
  color: #343434;
}

.update_ahu_btn_disabled {
  cursor: not-allowed;
  background-color: lightgrey !important;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(
    255,
    255,
    255,
    0.5
  ); /* Adjust the last value (alpha) for the level of transparency */
}
.helperText {
  color: red; /* Adjust color as needed */
  font-size: 14px; /* Adjust font size as needed */
  text-align: left;
  margin-top: 0;
}
