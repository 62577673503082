.ahu_container {
  /* background-color: white; */
}

.ahu_graph_container {
  width: 100%;
  background-color: white;
  padding: 2rem;
  /* border: 2px solid red; */
}

.ahu_cards {
  text-align: center;
  background-color: #fff;
  padding: 10px;
  border-radius: 5px;
  /* border: 2px solid red; */
  width: 100%;
  display: grid;
  place-items: center;
  /* height: 150px; */
}

.ahu_cards .ahu_card_title {
  font-size: 40px;
  font-weight: bold;
  color: #344a53;
  display: block;
  margin-bottom: 10px;
}

.ahu_cards .ahu_card_description {
  font-size: 16px;
  font-weight: 500;
  color: #344a53;
  display: block;
  margin-bottom: 10px;
}

.ahu_card_time {
  font-size: 12px;
  font-weight: 500;
  color: gray;
}

/* Adjust the chart size for mobile screens */
@media (max-width: 768px) {
  .ahu_graph_container {
    padding: 1rem;
  }
}
