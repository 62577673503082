.admin_cards_container {
  margin: 0px;
  display: flex;
  flex-wrap: wrap;
}
.admin_card {
  background-color: #1fa984;
  display: grid;
  place-items: center;
  text-align: center;
  padding: 20px;
  min-width: 250px;
  max-width: auto;
  border-radius: 5px;
  color: white;
  font-family: "Montserrat";
  margin: 0 20px 20px 0;
}

.admin_card .title {
  font-size: 4rem;
}

.admin_card .subtitle {
  font-size: 1.2rem;
}

.customer_form {
  position: relative;
}

.modal_close_btn {
  position: absolute;
  left: 0;
  bottom: 0;
}

.customer_form_title {
  font-weight: bold;
  color: #1fa984;
  font-size: 1.6rem;
  margin-bottom: 1.3rem;
  font-family: "Montserrat";
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.customer_input_container {
  margin-bottom: 16px;
  position: relative;
}

.customer_input_container .helper_text {
  font-size: 12px;
  font-family: "Montserrat";
  margin-top: 7px;
  font-weight: 500;
}
.customer_input_container div {
  width: 100%;
}
.customer_input_container label {
  color: gray;
}

.customer_input_container label:focus {
  color: #1fa984;
}

.customer_input {
  width: 100%;
  margin-top: 10px;
  padding: 10px;
  outline: none;
  border-radius: 4px;
  border: none;
  /* background-color: #e5e7eb; */
}

.customer_input:focus {
  border: 2px solid #1fa984;
}

.icon {
  padding: 10px;
  background: dodgerblue;
  color: white;
  text-align: center;
}

.input_helper_text {
  font-size: 16px;
  margin: 0;
  line-height: 1.5;
  margin-bottom: 10px;
  color: rgb(195, 28, 28);
}
