@font-face {
  font-family: "DMSans";
  src: url("../public/fonts/DMSans.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "DMSerifDisplay";
  src: url("../public//fonts/DMSerifDisplay.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
